<template>
  <b-card no-body>
    <!-- Recommend Course Modal For Counselor -->
    <b-modal :no-close-on-backdrop="true" id="reccomend-modal-id" ref="ref-reccomend-modal" centered title="Select Students" ok-title="Submit"
      hide-footer @hidden="resetSelectedStudents">
      <br />
      <b-button @click="recommendToStudents"> RECCOMEND </b-button>
      <br />
      <br />
      {{ this.recommend_course ? this.recommend_course.course_name : "" }}
      <br />
      <v-select multiple :reduce="(student) => student.student_user_id" label="unique_name" v-model="students_selected"
        :options="assigned_students" :closeOnSelect="false">
        <template #selected-option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
    </b-modal>

    <!-- Apply To Course Modal For Agent -->
    <b-modal :no-close-on-backdrop="true" id="agent-apply-modal-id" ref="ref-agent-apply-modal" centered title="Select Student" ok-title="Apply"
      no-close-on-backdrop @hidden="clearModalDetails" @ok.prevent="applyCourseForAgent">
      <br />
      {{
        this.selected_apply_course ? this.selected_apply_course.course_name : ""
      }}
      <br />
      <br />
      <br />
      Intake month
      <v-select v-model="selected_intake_month" :options="selected_course_intakes" />
      <br />
      Intake Year
      <v-select v-model="selected_intake_year" :options="all_years" />
      <br />
      Select Student
      <v-select :reduce="(student) => student.student_user_id" label="unique_name" v-model="selected_student_user_id"
        :options="assigned_students" :closeOnSelect="true">
        <template #selected-option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
    </b-modal>

    <!-- Select intake month and year student modal -->
    <b-modal :no-close-on-backdrop="true" id="id-student-select-intake-modal" ref="ref-student-select-intake-modal" centered title="Select Intake"
      ok-title="Apply" no-close-on-backdrop @hidden="clearModalDetails" @ok.prevent="applyToCourse">
      <br />
      {{
        this.selected_apply_course ? this.selected_apply_course.course_name : ""
      }}
      <br />
      <br />
      Intake month
      <v-select v-model="selected_intake_month" :options="selected_course_intakes" />
      <br />
      Intake Year
      <v-select v-model="selected_intake_year" :options="all_years" />
      <br />
      Campus Locations
      <v-select v-model="selected_campus_location" :options="campus_locations" />
    </b-modal>

    <div class="m-2">
      <div class="search-container mb-2">
        <label class="search-bar">
          <input type="text" autofocus v-model="filters.search_text" @keyup="getCourseListByFilter" />
          <img src="@/assets/images/svg/search.svg" alt="" />
        </label>
        <div class="search-results">
          You found <span>{{ all_universities_count }} institutes</span> and
          <span>{{ all_courses_count }} courses</span>
        </div>
        <!-- 
        <div class="search-filter-section pr-1" style="margin-right: 2.1rem">
          <div>
            <div class="d-flex justify-content-between" @click="() => {
              showAdvancedFilters = !showAdvancedFilters;
            }
              ">
              <h6 style="color: #000">Advanced Filters</h6>
              <div>
                <feather-icon icon="ChevronUpIcon" size="12" v-if="showAdvancedFilters" />
                <feather-icon icon="ChevronDownIcon" size="12" v-else />
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-end" style="gap: 20px" v-if="showAdvancedFilters">
            <div
              class="d-flex flex-column mt-2 border p-1 h-100 rounded-lg w-100 justify-content-center align-items-center"
              style="min-height: 120px">
              <div class="search-label-title d-flex justify-content-between pr-1">
                <span><b>Level</b></span>
              </div>
              <div class="search-checkbox-list d-flex flex-wrap mt-1 justify-content-center" style="gap: 20px">
                <CustomCheckbox v-for="course_level in $store.getters['home/getCourseLevels']"
                  :title="course_level.course_level_name" :id="course_level.course_level_id" :checked="filters.course_level_ids.includes(
                    course_level.course_level_id
                  )
                    " :key="'course_level_' + course_level.course_level_id" @checkbox-click="courseLevelSelected" />
              </div>
            </div>
            <div class="text-black border p-1 rounded-lg w-100 d-flex align-items-end justify-content-center"
              style="min-height: 120px">
              <b-row class="align-items-end">
                <b-col class="mb-1">
                  <multi-state-switch label="STEM" :options="tss_options" v-model="filters.stem_course"
                    @input="getCourseListByFilter" class="black-text" />
                </b-col>
                <b-col class="mb-1">
                  <multi-state-switch label="IELTS Wavier" :options="tss_options" v-model="filters.ielts_wavier"
                    @input="getCourseListByFilter" />
                </b-col>
                <b-col v-if="
                  filters.course_level_names.some((el) =>
                    ['Master/PG', 'MBA'].includes(el)
                  )
                " class="mb-1 text-center">
                  <multi-state-switch label="15 Yrs Acceptable" :options="tss_options"
                    v-model="filters.fifteen_years_education_acceptable" @input="getCourseListByFilter" />
                </b-col>
                <b-col class="mb-1" v-if="
                  filters.course_level_names.some((el) =>
                    ['Master/PG', 'MBA'].includes(el)
                  )
                ">
                  <multi-state-switch label="GRE REQUIRED" :options="tss_options" v-model="filters.gre_requirment"
                    @input="getCourseListByFilter" />
                </b-col>
                <b-col class="mb-1" v-if="
                  filters.course_level_names.some((el) =>
                    ['Diploma', 'Bachelor'].includes(el)
                  )
                ">
                  <multi-state-switch label="SAT REQUIRED" :options="tss_options" v-model="filters.sat_requirement"
                    @input="getCourseListByFilter" />
                </b-col>
              </b-row>
            </div>
          </div>
        </div> -->

        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex w-100 align-items-center justify-content-between">
              <div class="d-flex justify-content-center align-items-end flex-wrap w-100 px-1">
                <div>
                  <SearchFilterLabel v-for="(item, index) in filters.country_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.country_names.splice(index, 1);
                      filters.country_ids.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />

                  <!-- </div> -->
                  <!-- <div> -->
                  <SearchFilterLabel v-for="(item, index) in filters.city_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.city_names.splice(index, 1);
                      filters.city_ids.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />
                  <!-- </div> -->
                  <!-- <div> -->
                  <SearchFilterLabel v-for="(item, index) in filters.course_level_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.course_level_names.splice(index, 1);
                      filters.course_level_ids.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />
                  <!-- </div> -->
                  <!-- <div> -->
                  <SearchFilterLabel v-for="(item, index) in filters.course_category_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.course_category_names.splice(index, 1);
                      filters.course_category_ids.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />
                  <!-- </div>
              <div> -->
                  <SearchFilterLabel v-for="(item, index) in filters.tuition_fees_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.tuition_fees_names.splice(index, 1);
                      filters.tuition_fees.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />
                  <!-- </div>
              <div> -->
                  <SearchFilterLabel v-for="(item, index) in filters.tuition_usd_fees_names" :key="index" :title="item"
                    @cross-click="() => {
                      filters.tuition_usd_fees_names.splice(index, 1);
                      filters.tuition_usd_fees.splice(index, 1);
                      getCourseListByFilter();
                    }
                      " />

                </div>
              </div>
              <div>

                <b-badge @click="clearAllFilters" variant="primary" pill
                  style="cursor: pointer ; padding: 10px; margin-right: 30px">
                  Clear All Filters
                </b-badge>
              </div>
              <!-- <div>
                <b-badge @click="clearAllFilters" variant="primary" pill>
                  Clear All Filters
                </b-badge>
              </div> -->
            </div>
          </div>
        </div>


        <!-- Advance Filter Switches for Switz bitches -->

        <!-- Filter labels -->
      </div>

      <div class="row w-100">
        <!-- Left side filters checkboxes -->

        <div class="col-md-3">

          <div class="search-filter-section">
            <div class="search-label-title justify-content-between pr-1" @click="showDropdown(2)">
              <div>Discipline</div>
              <div v-if="isMobileScreen()">
                <feather-icon icon="ChevronUpIcon" size="12" v-if="displayDropdown[0]" />
                <feather-icon icon="ChevronDownIcon" size="12" v-else />
              </div>
            </div>
            <div class="search-checkbox-list mt-2" v-if="displayDropdown[2]">
              <CustomCheckbox v-for="course_category in $store.getters[
                'home/getCourseCategories'
              ]" :title="course_category.course_category_name" :id="course_category.course_category_id" :checked="filters.course_category_ids.includes(
                course_category.course_category_id
              )
                " :key="'course_category_' + course_category.course_category_id"
                @checkbox-click="courseCategorySelected" />
            </div>
          </div>

          <div class="search-filter-section">
            <!-- <div class="search-label-title d-flex justify-content-between pr-1" @click="showDropdown(0)">
              <div>Country</div>
              <div v-if="isMobileScreen()">
                <feather-icon icon="ChevronUpIcon" size="12" v-if="displayDropdown[0]" />
                <feather-icon icon="ChevronDownIcon" size="12" v-else />
              </div>
            </div>
            <div class="search-checkbox-list mt-2" v-if="displayDropdown[0]">
              <CustomCheckbox v-for="country in $store.getters['home/getCountriesAndCities']"
                :title="country.country_name" :id="country.country_id"
                :checked="filters.country_ids.includes(country.country_id)" :item_ids="filters.city_ids"
                :key="'country_' + country.country_id" :items="country.cities" @checkbox-click="countrySelected"
                @sub-checkbox-click="citySelected" />
            </div> -->

            <div class="search-label-title d-flex justify-content-between pr-1" @click="showDropdown(0)">
              <div>Level</div>
              <div v-if="isMobileScreen()">
                <feather-icon icon="ChevronUpIcon" size="12" v-if="displayDropdown[0]" />
                <feather-icon icon="ChevronDownIcon" size="12" v-else />
              </div>
            </div>
            <div class="search-checkbox-list mt-2" v-if="displayDropdown[0]">
              <CustomCheckbox v-for="course_level in $store.getters['home/getCourseLevels']"
                :title="course_level.course_level_name" :id="course_level.course_level_id" :checked="filters.course_level_ids.includes(
                  course_level.course_level_id
                )
                  " :key="'course_level_' + course_level.course_level_id" @checkbox-click="courseLevelSelected" />
            </div>
          </div>

          <div class="search-filter-section">
            <div style="margin-bottom: 0px" class="search-label-title d-flex align-items-center pr-1 mb-0" @click="() => {
              showDropdown(3);
              clearTuitionFee();
            }
              ">
              <span> Tuition Fees </span>
              <b-form-group v-slot="{ ariaDescribedby }" class="mb-0 mt-0">
                <div class="d-flex align-items-center">
                  <b-form-radio v-model="fee_currency" :aria-describedby="ariaDescribedby" name="fee-radios" value="INR"
                    class="mr-1">INR</b-form-radio>
                  <b-form-radio v-model="fee_currency" :aria-describedby="ariaDescribedby" name="fee-radios"
                    value="CHF">CHF</b-form-radio>
                </div>
              </b-form-group>
              <div v-if="isMobileScreen()">
                <feather-icon icon="ChevronUpIcon" size="12" v-if="displayDropdown[3]" />
                <feather-icon icon="ChevronDownIcon" size="12" v-else />
              </div>
            </div>
            <div class="search-checkbox-list mt-1" v-if="displayDropdown[3]">
              <div v-if="fee_currency === 'INR'">
                <CustomCheckbox v-for="(tuition_fee, index) in $store.getters[
                  'home/getTuitionFeesFilterValues'
                ]" :title="tuition_fee.name" :id="index" :checked="filters.tuition_fees_names.includes(tuition_fee.name)
                  " :key="'tuition_fee_filter_' + index" @checkbox-click="tuitionFeesSelected" />
              </div>
              <div v-if="fee_currency === 'USD'">
                <CustomCheckbox v-for="(tuition_usd_fee, index) in $store.getters[
                  'home/getTuitionUSDFeesFilterValues'
                ]" :title="tuition_usd_fee.name" :id="index" :checked="filters.tuition_usd_fees.includes(tuition_usd_fee.value)
                  " :key="'tuition_usd_fee_filter_' + index" @checkbox-click="tuitionUSDFeesSelected" />
              </div>
            </div>
          </div>
        </div>

        <!-- University Search List -->
        <div class="col-md-9" style="height: 100vh; overflow-y: scroll; scrollbar-width: none" id="university-list"
          v-infinite-scroll="onScroll" :infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-if="universityList && universityList.length === 0" class="university_list__empty">
            <h1>{{ universityListText }}</h1>
          </div>
          <div v-else class="university-list__section">
            <CourseProvider v-for="(data, index) in universityList" :key="index" :item="data"
              @reload-list="getCourseListByFilter" @recommend-course="showRecommendModal"
              @apply-course="showIntakeModal" @apply-course-for-student="showApplyModalForAgent" :filters="filters" />
          </div>
          <!-- <div>
            <b-pagination
              @change="onPagination"
              v-model="pagination.currentPage"
              :total-rows="all_universities_count"
              :per-page="10"
              base-url="#"
              align="right"
            />
          </div> -->
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BModal,
  BTooltip,
  BPagination,
  BFormRadio,
  BFormGroup,
} from "bootstrap-vue";
import CourseProvider from "./Search/helper/CourseProvider.vue";
import CustomCheckbox from "./Search/helper/CustomCheckbox";
import SearchFilterLabel from "./Search/helper/SearchFilterLabel.vue";
import { all_months, getAllYears } from "@/assets/json_data/data";
import vSelect from "vue-select";
import "./style.css";
import store from "@/store";
import { FILESURL } from "@/config";
import AgentServices from "@/apiServices/AgentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import CommonServices from "@/apiServices/CommonServices";
import StudentServices from "@/apiServices/StudentServices";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import helpers from "@/libs/helpers";
import infiniteScroll from "vue-infinite-scroll";


export default {
  components: {
    CourseProvider,
    CustomCheckbox,
    SearchFilterLabel,
    vSelect,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormGroup,
    BFormRadio,
    MultiStateSwitch,
  },
  directives: { infiniteScroll },
  data() {
    return {
      FILESURL,
      universityListText: "Loading...",
      tss_options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "Y",
        },
        {
          label: "No",
          value: "N",
        },
      ],
      filters: {
        search_text: "",
        search_country: "",
        search_course_level: "",
        search_course_category: "",
        order_by: "",
        order: "",
        course_level_ids: [],
        course_level_names: [],
        course_category_ids: [],
        course_category_names: [],
        country_ids: [],
        city_ids: [],
        country_names: [],
        city_names: [],

        tuition_fees: [],
        tuition_fees_names: [],

        tuition_usd_fees: [],
        tuition_usd_fees_names: [],

        // Advance Filters
        stem_course: "",
        fifteen_years_education_acceptable: "",
        ielts_wavier: "",
        gre_requirment: "",
        sat_requirement: "",
      },
      all_courses_count: 0,
      all_universities_count: 0,
      universityList: [],
      universityList: [],
      displayDropdown: [true, true, true, true],
      showAdvancedFilters: false,

      // Agent Apply
      selected_student_user_id: null,

      selected_intake_month: null,
      selected_intake_year: null,
      selected_apply_course: null,
      selected_course_intakes: [],

      selected_campus_location: null,
      campus_locations: [],

      // Counselor Reccomendation
      students_selected: [],
      recommend_course: null,
      busy: false,

      pagination: {
        perPage: 20,
        currentPage: 1,
      },

      //  Debounce
      searchTimeout: null,

      all_months,
      fee_currency: "INR",
    };
  },

  computed: {
    all_years() {
      return getAllYears();
    },
    assigned_students() {
      return store.getters["user/getAssignedStudents"];
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  methods: {
    onPagination() {
      this.getCourseListByFilter();
    },

    isMobile() {
      if (screen.width <= 760) {
        this.displayDropdown = [false, false, false, false];
        return true;
      } else {
        this.displayDropdown = [true, true, true, true];

        return false;
      }
    },

    showDropdown(value) {
      if (screen.width <= 760) {
        this.displayDropdown.splice(value, 1, !this.displayDropdown[value]);
      }
    },

    isMobileScreen() {
      return screen.width <= 760;
    },

    setOrderFilter(order_by, order) {
      this.filters.order_by = order_by;
      this.filters.order = order;
      this.getCourseListByFilter();
    },

    clearTuitionFee() {
      if (
        this.filters.tuition_usd_fees.length ||
        this.filters.tuition_fees.length
      ) {
        setTimeout(() => {
          this.getCourseListByFilter();
        });
      }

      this.filters.tuition_usd_fees = [];
      this.filters.tuition_usd_fees_names = [];
      this.filters.tuition_fees = [];
      this.filters.tuition_fees_names = [];
    },

    tuitionFeesSelected(index, name) {
      if (
        JSON.stringify(this.filters.tuition_fees) ===
        JSON.stringify([
          store.getters["home/getTuitionFeesFilterValues"][index].value,
        ])
      ) {
        this.filters.tuition_fees = [];
        this.filters.tuition_fees_names = [];
      } else {
        this.filters.tuition_fees = [
          store.getters["home/getTuitionFeesFilterValues"][index].value,
        ];
        this.filters.tuition_fees_names = [name];
      }
      this.getCourseListByFilter();
    },

    tuitionUSDFeesSelected(index, name) {
      if (
        JSON.stringify(this.filters.tuition_usd_fees) ===
        JSON.stringify([
          store.getters["home/getTuitionUSDFeesFilterValues"][index].value,
        ])
      ) {
        this.filters.tuition_usd_fees = [];
        this.filters.tuition_usd_fees_names = [];
      } else {
        this.filters.tuition_usd_fees = [
          store.getters["home/getTuitionUSDFeesFilterValues"][index].value,
        ];
        this.filters.tuition_usd_fees_names = [name];
      }
      this.getCourseListByFilter();
    },

    countrySelected(id, name) {
      if (this.filters.country_ids.includes(id)) {
        this.filters.country_ids.splice(
          this.filters.country_ids.indexOf(id),
          1
        );
        this.filters.country_names.splice(
          this.filters.country_names.indexOf(name),
          1
        );
      } else {
        this.filters.country_ids.push(id);
        this.filters.country_names.push(name);
      }
      this.getCourseListByFilter();
    },

    citySelected(id, name) {
      if (this.filters.city_ids.includes(id)) {
        this.filters.city_ids.splice(this.filters.city_ids.indexOf(id), 1);
        this.filters.city_names.splice(
          this.filters.city_names.indexOf(name),
          1
        );
      } else {
        this.filters.city_ids.push(id);
        this.filters.city_names.push(name);
      }
      this.getCourseListByFilter();
    },

    courseLevelSelected(id, name) {
      if (this.filters.course_level_ids.includes(id)) {
        this.filters.course_level_ids.splice(
          this.filters.course_level_ids.indexOf(id),
          1
        );
        this.filters.course_level_names.splice(
          this.filters.course_level_names.indexOf(name),
          1
        );
      } else {
        this.filters.course_level_ids.push(id);
        this.filters.course_level_names.push(name);
      }
      this.getCourseListByFilter();
    },

    courseCategorySelected(id, name) {
      if (this.filters.course_category_ids.includes(id)) {
        this.filters.course_category_ids.splice(
          this.filters.course_category_ids.indexOf(id),
          1
        );
        this.filters.course_category_names.splice(
          this.filters.course_category_names.indexOf(name),
          1
        );
      } else {
        this.filters.course_category_ids.push(id);
        this.filters.course_category_names.push(name);
      }
      this.getCourseListByFilter();
    },

    async onScroll() {
      this.busy = true;
      if (this.universityList.length >= this.all_universities_count) {
        return;
      }

      this.pagination.currentPage++;
      await this.onChange();

      this.busy = false;
    },

    getCourseListByFilter() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        document.getElementById('university-list').scrollTop = 0
        this.pagination.currentPage = 1;
        this.universityList = []
        this.onChange();
      }, 1000);
    },

    async onChange() {
      await this.getCourses({
        searchText: this.filters.search_text,
        order_by: this.filters.order_by,
        order: this.filters.order,
        country_ids: this.filters.country_ids.toString(),
        city_ids: this.filters.city_ids.toString(),
        course_level_ids: this.filters.course_level_ids.toString(),
        course_category_ids: this.filters.course_category_ids.toString(),
        tuition_fees: this.filters.tuition_fees[0],
        tuition_usd_fees: this.filters.tuition_usd_fees[0],

        // Advance Filters
        stem_course: this.filters.stem_course,
        fifteen_years_education_acceptable:
          this.filters.fifteen_years_education_acceptable,
        ielts_wavier: this.filters.ielts_wavier,
        gre_requirment: this.filters.gre_requirment,
        sat_requirement: this.filters.sat_requirement,
      });
    },

    async getCourses(payload = {}) {
      const getCoursesLoader = this.$loading.show();
      try {
        payload = {
          ...payload,
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
        };
        let response = await CommonServices.getCourses(payload);

        if (!response.data.status || !response.data.data.data) {
          this.universityListText = "No Results Found";
          return;
        }

        localStorage.setItem("search_filters", JSON.stringify(this.filters));

        const course_arr = response.data.data.data;
        if (!course_arr.length) {
          this.universityListText = "No Results Found";
        }
        this.all_universities_count = response.data.data.all_universities_count;
        this.all_courses_count = response.data.data.all_courses_count;
        if (this.pagination.currentPage === 1) {
          this.universityList = course_arr;
        } else {
          const uniList = JSON.parse(JSON.stringify(this.universityList));
          this.universityList = uniList.concat(course_arr);
        }
      } catch (error) {
        console.error("Error in getting Courses ", error);
      } finally {
        getCoursesLoader.hide();
      }
    },

    clearModalDetails() {
      this.selected_student_user_id = null;
      this.selected_apply_course = null;
      this.selected_intake_month = null;
      this.selected_intake_year = null;
      this.selected_campus_location = null;
    },

    showApplyModalForAgent(e) {
      this.selected_apply_course = e;
      this.selected_course_intakes = helpers.formatIntakeForApplyModal(
        e.intake
      );

      this.$refs["ref-agent-apply-modal"].show();
    },

    async applyCourseForAgent() {
      try {
        const intake_month = this.selected_intake_month;
        const intake_year = this.selected_intake_year;
        if (!intake_month || !intake_year || !this.selected_student_user_id) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Select Intake and student",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }
        const response = await AgentServices.applyForStudent({
          student_user_id: this.selected_student_user_id,
          course_id: this.selected_apply_course.course_id,
          intake_month,
          intake_year,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Successfully Applied",
              icon: "Check",
              variant: "success",
            },
          });
          this.$refs["ref-agent-apply-modal"].hide();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Not Applied",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in applying",
            icon: "X",
            variant: "failure",
          },
        });
        console.error("Error in applying to course for agent", err);
      }
    },

    resetSelectedStudents() {
      this.students_selected = [];
      this.recommend_course = null;
      this.$refs["ref-reccomend-modal"].hide();
    },

    showRecommendModal(e) {
      this.recommend_course = e;
      this.$refs["ref-reccomend-modal"].show();
    },

    async recommendToStudents(e) {
      try {
        const response = await CommonServices.reccomendCourseToStudents({
          course_id: this.recommend_course.course_id,
          student_user_ids: this.students_selected,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.error("Error in recommending course to student ", err);
      }
      this.resetSelectedStudents();
    },

    async showIntakeModal(course) {
      this.selected_apply_course = course;
      this.selected_course_intakes = helpers.formatIntakeForApplyModal(
        course.intake
      );

      this.campus_locations = helpers.formatIntakeForApplyModalForCampus(
        course.multiple_campus_locations
      );

      this.$refs["ref-student-select-intake-modal"].show();
    },

    applyToCourse() {
      const course_id = this.selected_apply_course.course_id;
      const intake_month = this.selected_intake_month;
      const intake_year = this.selected_intake_year;
      const campus_location = this.selected_campus_location;

      if (!intake_month || !intake_year || !campus_location) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Select Intake and Campus Locations",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }
      StudentServices.ApplyCourse({
        course_id,
        intake_month,
        intake_year,
        campus_location,
      })
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course Applied Successfully",
                icon: "CheckSquareIcon",
                variant: "success",
              },
            });
            this.getCourseListByFilter();
            this.$refs["ref-student-select-intake-modal"].hide();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Cannot Apply to course",
                icon: "X",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.error("Error Applying to course ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error Applying to course",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },

    async setFiltersAndGetCourseList() {
      const filters = localStorage.getItem("search_filters");
      if (filters) {
        this.filters = {
          ...this.filters,
          ...JSON.parse(filters),
        };
        this.getCourseListByFilter();
      } else {
        if (this.user_type && this.user_type === "student") {
          await this.getStudentPreferences();
        }
        // this.getCourses();
        this.getCourseListByFilter();
      }
    },

    clearAllFilters() {
      this.filters = {
        search_text: "",
        search_country: "",
        search_course_level: "",
        search_course_category: "",
        order_by: "",
        order: "",
        course_level_ids: [],
        course_level_names: [],
        course_category_ids: [],
        course_category_names: [],
        country_ids: [],
        city_ids: [],
        country_names: [],
        city_names: [],

        tuition_fees: [],
        tuition_fees_names: [],

        tuition_usd_fees: [],
        tuition_usd_fees_names: [],

        stem_course: "",
        fifteen_years_education_acceptable: "",
        ielts_wavier: "",
        gre_requirment: "",
        sat_requirement: "",
      };

      this.pagination.currentPage = 1;
      this.getCourseListByFilter();
    },

    getNumberArray(list) {
      if (!list) return [];

      const stringList = list.split(",");
      let finalList = [];
      for (let item of stringList) {
        finalList.push(parseInt(item));
      }

      return finalList;
    },

    getStringArray(list) {
      if (!list) return [];
      const stringList = list.split(",");
      return stringList;
    },

    async getStudentPreferences() {
      const filters = localStorage.getItem("search_filters");
      if (filters) return;

      const res = await StudentServices.getCoursePreferences();

      this.filters.course_category_ids.push(
        res.data.data.pref_course_category_id
      );
      this.filters.course_category_names.push(
        res.data.data.course_category_name
      );

      const country_ids = this.getNumberArray(res.data.data.pref_country_id);
      for (let id of country_ids) {
        this.filters.country_ids.push(id);
      }

      // this.filters.country_ids.push(res.data.data.pref_country_id);

      const country_names = res.data.data.country_name;
      for (let name of country_names) {
        this.filters.country_names.push(name);
      }

      // this.filters.country_names.push(res.data.data.country_name);

      this.filters.course_level_ids.push(res.data.data.pref_course_level_id);
      this.filters.course_level_names.push(res.data.data.course_level_name);

      this.filters.tuition_fees = [res.data.data.tuition_budget.value];
      this.filters.tuition_fees_names = [res.data.data.tuition_budget.name];
    },
  },
  beforeMount() {
    this.isMobile();
    this.setFiltersAndGetCourseList();
    this.$store.dispatch("home/onGetUniversityList");
    this.$store.dispatch("home/onGetCountriesAndCities");
    this.$store.dispatch("home/onGetCourseLevels");
    this.$store.dispatch("home/onGetCourseCategories");
    this.$store.dispatch("home/onGetTuitionFeesFilterValues");
    this.$store.dispatch("home/onGetTuitionUSDFeesFilterValues");
  },
  watch: {
    user_type(newVal) {
      if (newVal && newVal === "student") {
        this.getStudentPreferences();
      }
      this.getCourseListByFilter();
    },
  },
};
</script>

<style scoped>
.black-text {
  color: black !important;
}

.clear-button {
  background-color: #5367ff;
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 8px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.clear-button:hover {
  background-color: white;
  color: #5367ff;
  border: 1px solid #5367ff;
}

.advance-filters {
  display: flex;
  flex-direction: row;
}

.search-filter-label {
  padding: 13px !important;
  font-size: 13px;
  margin-bottom: 0px;
}

/* .tss-item {
  margin-inline-start: 2rem;
  margin-inline-end: 2rem;
} */
</style>
